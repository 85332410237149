
import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { qa, diploma, rolled } from '../../images/icons';
import { teaching_team } from '../../images/team';

export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>High School Admissions Mentorship</div>
          <div className={styles.description}>Our high school counseling program helps students find school communities best fit for their needs in order to achieve their long-term academic goals. Our preparation plans and roadmaps will help students strategize a competitive and effective application to help improve their candidacy in an increasingly competitive applicant pool.</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={qa} />
          <div className={styles.meta}>Individualized Guidance</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>Meet 1-on-1 with an expert consultants to receive tailored guidance on school selection, interview preparation, and feedback for application essay writing. We use a team-based approach, so each student in our program benefits from the expertise of each of our counselors.</div>
        </div>
        <div className={styles.card}>
          <img src={diploma} />
          <div className={styles.meta}>Incredible Outcomes</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>Our results speak for themselves: 31% of Blueprint students have received an offer from a top-10 US Boarding or Day School; 80% of Blueprint students attend a top-50 Boarding or Day School.</div>
        </div>
        <div className={styles.card}>
          <img src={rolled} />
          <div className={styles.meta}>Real Expertise</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Our Shanghai-based team and global network of mentors include former admissions officers from some of the top institutions in the US. Competitive admissions is constantly changing, and our team of experienced counselors are best positioned to give you an edge in the process. <Link to='/team/main'>Meet our team here.</Link></div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Want to learn more about how our counseling programs can help you achieve your goals?' cta='Schedule a Free Consultation'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Key Program Features</div>
        <div className={styles.description}>Our admissions mentorship program provides 1-on-1 guidance for up to 8 high school applications. We typically begin the spring before a student’s application year, lasting through their school acceptances and offering tertiary support into their school experience. Meetings occur weekly.</div>
        <div className={styles.description}>Here are some more key details about the program’s curriculum:</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>School Selection</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Create a preliminary list of 12-15 schools</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Conduct rigorous, quantitative and qualitative research on each institution, while encouraging in person visits, to determine fit</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Connect with alumni, current students, parents, and faculty of each school</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Narrow the preliminary list down to a final list of 8 best-fit schools</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Storytelling Support</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Develop effective narrative skills with Blueprint’s proprietary storytelling curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Communicate a compelling “hook” that showcases strengths and helps students  stand out</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Receive end-to-end support on all application essays right up until final deadlines</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Learn how to tell your story in a way that showcases your strengths and makes your application stand out from the crowd</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Profile Development</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Receive comprehensive interview preparation with structured feedback</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Ensure key deadlines are met with effective application time management</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Learn how to effectively show “demonstrated interest” in your top schools</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Get advice on how to transition to life overseas and secure competitive summer positions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)